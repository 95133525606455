import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import {
    ArticleCard,
    articleCardContentMarkerVariants,
    articleCardDecorationVariants,
    articleCardVariants
} from '../Article/components/Card'
import { ProductCard, productCardVariants } from '../Product/components/Card'

const ArticleProps = {
    article: {
        contentMarkerVariant: articleCardContentMarkerVariants.NONE
    },
    gallery: {
        contentMarkerVariant: articleCardContentMarkerVariants.PHOTO
    },
    video: {
        contentMarkerVariant: articleCardContentMarkerVariants.VIDEO
    }
}

function EntityCard({
    item,
    cardVariant,
    cardDecorationVariant,
    gtmData,
    imageVariation,
    imageHeight,
    imageWidth,
    imageLazyLoad,
    imagePreload,
    columnist,
    ...rest
}) {
    const [isDesktop] = useBreakpoints('md')
    const articleProps = useMemo(() => {
        const props = ArticleProps[item.type] || {}
        if (item.type !== 'product') {
            props.variant = isDesktop ? articleCardVariants.GAMMA : articleCardVariants.BETA
        }
        return props
    }, [item, isDesktop])

    if (item.type === 'product') {
        return (
            <ProductCard
                item={item}
                href={item.sitemap.href}
                titleTagComponent="h3"
                imageVariation={imageVariation}
                imageUseLargestInstance
                imageHeight={imageHeight}
                imageWidth={imageWidth}
                imageLazyLoad={imageLazyLoad}
                variant={productCardVariants.ALPHA}
                imagePreload={imagePreload}
                isWidget
            />
        )
    }

    if (item.type === 'article' || item.type === 'gallery' || item.type === 'video' || item.type === 'recipe') {
        const { variant, contentMarkerVariant } = articleProps
        return (
            <ArticleCard
                item={item}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                imageVariation={imageVariation}
                imageLazyLoad={imageLazyLoad}
                imageUseLargestInstance
                titleTagComponent="h3"
                href={item.sitemap.href}
                variant={cardVariant || variant}
                decorationVariant={cardDecorationVariant}
                contentMarkerVariant={contentMarkerVariant}
                gtmData={gtmData}
                imagePreload={imagePreload}
                columnist={columnist}
                {...rest}
            />
        )
    }

    return null
}

EntityCard.propTypes = {
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    cardVariant: PropTypes.oneOf([...Object.values(articleCardVariants)]),
    cardDecorationVariant: PropTypes.oneOf([...Object.values(articleCardDecorationVariants)]),
    imageVariation: PropTypes.shape({}),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageLazyLoad: PropTypes.bool,
    gtmData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imagePreload: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    columnist: PropTypes.string
}

EntityCard.defaultProps = {
    item: undefined,
    cardVariant: articleCardVariants.NONE,
    cardDecorationVariant: articleCardDecorationVariants.NONE,
    imageVariation: undefined,
    imageWidth: 300,
    imageHeight: 300,
    imageLazyLoad: undefined,
    gtmData: undefined,
    imagePreload: false,
    columnist: undefined
}

export default EntityCard
