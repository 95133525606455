import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Overall } from '../../../Comments/components'
import { Icon, ReviewerBadgeIcon } from '../../../Icon'
import { Image, imageRatioVariants } from '../../../Image'
import { Link } from '../../../Link'
import styles from './Card.style'

const ProductCardStyled = styled.div(props => ({ ...styles(props) }))

function ProductCard({
    item,
    imageWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight,
    imageVariation,
    imageLazyLoad,
    titleTagComponent: TitleTag,
    href,
    hrefAs,
    className,
    variant,
    isPrevNext,
    imagePreload,
    ...rest
}) {
    const { title, brand, image, score, reviews } = item
    const titleHtmlProps = useInnerHtml(title)
    const brandHtmlProps = useInnerHtml(brand?.name)

    if (!image && !title) {
        return null
    }

    return (
        <ProductCardStyled isPrevNext={isPrevNext} variant={variant} className={className} {...rest}>
            <Link href={href} as={hrefAs} title={title} className="cardInner">
                {(image || score?.overall) && (
                    <div className="cardHead">
                        <div className="cardImage_wrapper">
                            <Image
                                className="cardImage"
                                classNameProgressive="cardImage_progressive"
                                variation={imageVariation || imageRatioVariants.CUSTOM_SQUARE}
                                useLargestInstance={rest?.imageUseLargestInstance}
                                image={image}
                                width={imageWidth}
                                lazyLoad={imageLazyLoad}
                                hideAuthor
                                preload={!!imagePreload}
                                // height={imageHeight}
                            />
                        </div>
                        <div className="cardHead_infoWrap">
                            {score?.overall && (reviews || variant !== 'alpha') && (
                                <Overall size={30} value={score.overall} label="" max={5} className="cardScore" />
                            )}
                            {reviews && variant === 'alpha' && (
                                <div className="cardBadge">
                                    <Icon icon={ReviewerBadgeIcon} />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {(brand || title) && (
                    <div className="cardContent">
                        {brand && <div className="cardContent_brand" {...brandHtmlProps} />}
                        {title && <TitleTag className="cardContent_title" {...titleHtmlProps} />}
                    </div>
                )}
            </Link>
        </ProductCardStyled>
    )
}

const productCardVariants = Object.freeze({
    ALPHA: 'alpha', // serp listing
    BETA: 'beta', // prev-next
    GAMMA: 'gamma' // related product
})

ProductCard.propTypes = {
    item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    titleTagComponent: PropTypes.elementType,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageVariation: PropTypes.shape({}),
    imageLazyLoad: PropTypes.bool,
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(productCardVariants)]),
    isPrevNext: PropTypes.bool,
    imagePreload: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

ProductCard.defaultProps = {
    item: undefined,
    titleTagComponent: 'h3',
    imageWidth: 800,
    imageHeight: 800,
    imageVariation: undefined,
    imageLazyLoad: true,
    href: undefined,
    hrefAs: undefined,
    className: undefined,
    variant: productCardVariants.ALPHA,
    isPrevNext: undefined,
    imagePreload: false
}

export { productCardVariants }

export default ProductCard
