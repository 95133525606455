import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { articleCardDecorationVariants, articleCardVariants } from '../Article/components/Card'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { Graphic, graphicVariants } from '../Decoration/components'
import { Icon, ZenaSymbolIcon } from '../Icon'
import { imageRatioVariants } from '../Image'
import { Link } from '../Link'
import styles from './CardsBlock.style'

const getCardVariant = (index, variant, isDesktop) => {
    if ((index === 1 || index === 2) && variant === 'beta' && isDesktop) {
        return 'BETA'
    }
    if (index === 0 && !isDesktop) {
        return 'ETA'
    }
    return 'GAMMA'
}
const CardsBlockStyled = styled.section(props => ({ ...styles(props) }))
function CardsBlock({
    children,
    title,
    titleTagComponent: TitleTag,
    areaXs,
    areaMd,
    columnsXs,
    columnsMd,
    count,
    config,
    variant,
    className,
    url,
    separator,
    noBorder,
    ...rest
}) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])
    const titleHtmlProps = useInnerHtml(title)

    // @TODO: revisit this, seems to work fine for current use cases
    const minHeight = useMemo(() => {
        const items = Math.min(...[itemElements.length, count].filter(Boolean))
        const onePerColMd = columnsMd?.split(' ')?.length === items
        const onePerColXs = columnsMd?.split(' ')?.length === items
        const itemsInColMd = Math.ceil((items - 1) / (columnsMd?.split(' ').length || 1))
        const itemsInColXs = Math.ceil((items - 1) / (columnsXs?.split(' ').length || 1))

        return {
            md:
                (onePerColMd && 100) ||
                ((!columnsMd || columnsMd?.split(' ')?.length === 1) && 350 + 100 * itemsInColMd) ||
                Math.max(450, 100 * itemsInColMd),
            xs: (onePerColXs && 100) || 450 + 100 * itemsInColXs
        }
    }, [columnsMd, columnsXs, count, itemElements])
    if (!children.length) {
        return null
    }

    const isExternal = url?.indexOf('http') !== -1 && url?.indexOf('zena.net.hr') === -1

    return (
        <CardsBlockStyled
            className={className}
            variant={variant}
            url={url}
            noBorder={noBorder}
            separator={separator}
            areaXs={areaXs}
            areaMd={areaMd}
            columnsXs={columnsXs}
            columnsMd={columnsMd}
            minHeight={minHeight}
            {...rest}>
            {separator && <Graphic className="cardsBlock_separator" variant={graphicVariants.ALPHA} />}
            {title && (
                <ConditionalWrapper condition={!!url} Wrapper={Link} href={url} title={url} isExternal={isExternal}>
                    <TitleTag className="cardsBlock_title" {...titleHtmlProps} />
                </ConditionalWrapper>
            )}
            {!noBorder && (
                <Graphic className="cardsBlock_graphic" widthXs={190} heightXs={64} variant={graphicVariants.GAMMA} />
            )}
            <div className="cardsBlock_inner">
                <div className="cardsBlock_list">
                    {itemElements?.slice(0, count).map((child, index) => {
                        const childConfig = config && (config[index] || config[config.length - 1])
                        return (
                            <div key={uid(child?.props?.item?.id || index)} className="cardsBlock_item">
                                {cloneElement(child, {
                                    imageWidth:
                                        childConfig?.imageWidth || (variant === 'alpha' && isDesktop) ? 450 : 650,
                                    imageHeight:
                                        childConfig?.imageHeight || (variant === 'alpha' && isDesktop) ? 625 : 650,
                                    imageVariation:
                                        childConfig?.imageVariation || variant === 'alpha'
                                            ? imageRatioVariants.CUSTOM_PORTRAIT
                                            : imageRatioVariants.CUSTOM_SQUARE,
                                    variant:
                                        articleCardVariants[
                                            childConfig?.variant || getCardVariant(index, variant, isDesktop)
                                        ],
                                    decorationVariant:
                                        childConfig?.decorationVariant || (index === 0 && variant === 'beta')
                                            ? articleCardDecorationVariants.ALPHA
                                            : null
                                })}
                            </div>
                        )
                    })}
                </div>
                {!noBorder && <Icon className="cardsBlock_icon" icon={ZenaSymbolIcon} size={30} />}
            </div>
            {separator && <Graphic className="cardsBlock_separator" variant={graphicVariants.ALPHA} />}
        </CardsBlockStyled>
    )
}

const cardsBlockVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

CardsBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    variant: PropTypes.oneOf([...Object.values(cardsBlockVariants)]),
    areaXs: PropTypes.string,
    areaMd: PropTypes.string,
    columnsXs: PropTypes.string,
    columnsMd: PropTypes.string,
    url: PropTypes.string,

    config: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    count: PropTypes.number,
    separator: PropTypes.bool,
    noBorder: PropTypes.bool
}
CardsBlock.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h2',
    variant: cardsBlockVariants.ALPHA,

    config: undefined,
    areaXs: undefined,
    areaMd: undefined,
    columnsXs: undefined,
    columnsMd: undefined,
    url: undefined,
    count: 3,
    separator: false,
    noBorder: false
}

export { cardsBlockVariants }

export default withErrorBoundary(CardsBlock, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[CardsBlock]: ', error, componentStack)
    }
})
