/* eslint-disable max-len */
import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant, isPrevNext, isWidget }) => {
    const config = {
        badge: {
            size: 85,
            padding: 5
        },
        xs: {
            alpha: {
                container: {
                    maxWidth: '100%'
                },
                head: {
                    height: toRem(190),
                    width: toRem(190),
                    maxWidth: '100%'
                },
                content: {
                    width: `calc(100% - ${toRem(45)})`,
                    padding: toRems([15, 15, 15, 25]),
                    transform: `translateX(${toRem(-50)})`,
                    fontSize: {
                        brand: toRem(15),
                        title: toRem(18)
                    }
                }
            },
            beta: {
                container: {
                    maxWidth: '100%'
                },
                head: {
                    height: 'auto',
                    width: '100%',
                    maxWidth: '100%'
                },
                content: {
                    width: `100%`,
                    padding: toRems([15, 15, 15, 25]),
                    fontSize: {
                        brand: toRem(15),
                        title: toRem(18)
                    }
                }
            },
            gamma: {
                container: {
                    maxWidth: '100%'
                },
                head: {
                    height: toRem(100),
                    width: toRem(100),
                    maxWidth: '100%'
                },
                content: {
                    width: '70%',
                    padding: toRems([20, 15, 18, 25]),
                    fontSize: {
                        brand: toRem(13),
                        title: toRem(14)
                    }
                }
            }
        },
        md: {
            alpha: {
                container: {
                    maxWidth: '100%'
                },
                head: {
                    height: toRem(300),
                    width: '100%',
                    maxWidth: '100%'
                },
                content: {
                    width: `calc(100% - ${toRem(50)})`,
                    padding: toRems([14, 15, 14, 25]),
                    margin: toRems([-50, 0, 0, 0]),
                    fontSize: {
                        brand: toRem(18),
                        title: toRem(24)
                    }
                }
            },
            beta: {
                container: {
                    maxWidth: toRem(500)
                },
                head: {
                    height: 'auto',
                    width: '100%',
                    maxWidth: isPrevNext ? toRem(500) : toRem(300)
                },
                content: {
                    width: `100%`,
                    padding: toRems([14, 15, 14, 25]),
                    fontSize: {
                        brand: toRem(16),
                        title: toRem(20)
                    }
                }
            },
            gamma: {
                container: {
                    maxWidth: '100%'
                },
                head: {
                    height: toRem(300),
                    width: '100%',
                    maxWidth: '100%'
                },
                content: {
                    width: `100%`,
                    padding: toRems([14, 15, 14, 25]),
                    fontSize: {
                        brand: toRem(13),
                        title: toRem(14)
                    }
                }
            }
        }
    }

    return {
        position: 'relative',
        padding: 0,
        maxWidth: config.xs[variant]?.container?.maxWidth,
        [theme.breakpoints.up('md')]: {
            maxWidth: config.md[variant]?.container?.maxWidth
        },
        ...(variant === 'gamma' && {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up('md')]: {
                display: 'block'
            }
        }),
        ...(variant === 'alpha' && {
            '& .cardBadge': {
                background: theme.colors.product?.card?.badge?.background || theme.colors.devWarning,
                borderRadius: '50%',
                '& svg': {
                    width: toRem(config.badge.size - config.badge.padding),
                    height: toRem(config.badge.size - config.badge.padding),
                    color: theme.colors.product?.card?.badge?.color || theme.colors.devWarning,
                    '& path': {
                        fill: 'currentColor'
                    }
                }
            }
        }),
        '& .cardInner': {
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
            ...(['alpha', 'gamma'].includes(variant) && {
                flexDirection: 'row',
                alignItems: 'flex-end',
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'column'
                }
            }),
            color: theme.colors.product?.card?.content?.text || theme.colors.devWarning
        },
        '& .cardHead': {
            position: 'relative',
            ...(['alpha', 'gamma'].includes(variant) && {
                flexShrink: 0,
                flexGrow: 0,
                [theme.breakpoints.up('md')]: {
                    flexGrow: 1
                }
            }),
            width: config.xs[variant]?.head?.width,
            height: isWidget ? '100%' : config.xs[variant]?.head?.height,
            maxWidth: config.xs[variant]?.head?.maxWidth,
            [theme.breakpoints.up('md')]: {
                width: config.md[variant]?.head?.width,
                height: isWidget ? '100%' : config.md[variant]?.head?.height,
                maxWidth: config.md[variant]?.head?.maxWidth
            }
        },
        '& .cardHead_infoWrap': {
            position: 'absolute',
            width: toRem(85),
            height: toRem(85),
            top: toRem(2),
            right: toRem(2)
        },
        '& .cardScore': {
            position: 'absolute',
            ...(variant === 'alpha' && {
                backgroundColor: theme.colors.background.body.default,
                borderRadius: '50%',
                right: toRem(-5)
            })
        },
        '& .cardImage_wrapper': {
            position: 'relative',
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                padding: toRems([0, 50, 0, 50])
            }
        },
        '& .cardImage': {
            position: 'relative',
            width: '100%',
            height: '100%',
            backgroundImage: 'none',
            '& img': {
                position: 'relative',
                objectFit: 'contain'
            }
        },
        '& .cardImage_progressive': {
            height: '100%',
            position: 'relative',
            padding: 0
        },
        '& .cardContent': {
            background: theme.colors.product?.card?.content?.background || theme.colors.devWarning,
            width: config?.xs[variant]?.content?.width,
            padding: config?.xs[variant]?.content?.padding,
            transform: config?.xs[variant]?.content?.transform,
            [theme.breakpoints.up('md')]: {
                width: config?.md[variant]?.content?.width,
                padding: config?.md[variant]?.content?.padding,
                transform: config?.md[variant]?.content?.transform,
                margin: config?.md[variant]?.content?.margin
            }
        },
        '& .cardContent_brand': {
            marginBottom: toRem(4),
            color: theme.colors.product?.card?.content?.text || theme.colors.devWarning,
            fontSize: config?.xs[variant]?.content?.fontSize?.brand,
            lineHeight: 1.4,
            [theme.breakpoints.up('md')]: {
                marginBottom: 0,
                fontSize: config?.md[variant]?.content?.fontSize?.brand,
                lineHeight: 1.6
            }
        },
        '& .cardContent_title': {
            color: theme.colors.product?.card?.content?.title?.default || theme.colors.devWarning,
            fontFamily: theme.typography.fontFamilyAlt,
            fontStyle: theme.typography.font.style.italic,
            fontSize: config?.xs[variant]?.content?.fontSize?.title,
            fontWeight: theme.typography.fontWeight.regular,
            lineHeight: 1.6,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'color',
            [theme.breakpoints.up('md')]: {
                fontSize: config?.md[variant]?.content?.fontSize?.title
            }
        },
        '&:hover': {
            '& .cardContent_title': {
                color: theme.colors.product?.card?.content?.title?.hover || theme.colors.devWarning
            }
        }
    }
}

export default style
