import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({
    theme,
    variant,
    url,
    // separator,
    noBorder,
    areaXs,
    areaMd = areaXs,
    areaLg = areaMd,
    columnsXs,
    columnsMd = columnsXs,
    columnsLg = columnsMd,
    gridColumnGapXs,
    gridColumnGapMd,
    gridColumnGapLg,
    gridRowGapXs,
    gridRowGapMd,
    gridRowGapLg,

    minHeight
}) => {
    const variantOptions = {
        alpha: {
            md: {
                padding: toRems([20, 20, 0, 25]),
                gridTemplateColumns: '1fr 1fr',
                gridColumnGap: toRem(25),
                alignItems: 'flex-start',
                gridRowGap: 0
            },
            lg: {
                padding: toRems([50, 50, 0]),
                gridTemplateColumns: '1fr 1.134fr 1fr',
                gridColumnGap: toRem(50),
                alignItems: 'flex-start',
                gridRowGap: 0
            }
        },
        beta: {
            md: {
                padding: toRems([20, 20, 0, 25]),
                gridTemplateColumns: '1fr',
                gridColumnGap: toRem(40),
                alignItems: 'center',
                gridRowGap: toRem(40)
            },
            lg: {
                padding: toRems([50, 80, 0]),
                gridTemplateColumns: '1fr 1fr 2fr',
                gridColumnGap: toRem(100),
                alignItems: 'center',
                gridRowGap: toRem(50)
            }
        }
    }

    const variantConfig = variantOptions[variant]

    const config = {
        xs: {
            gridTemplateColumns: columnsXs || variantConfig?.xs?.gridTemplateColumns,
            gridTemplateAreas: areaXs || variantConfig?.xs?.gridTemplateAreas,
            gridRowGap: toRem(gridRowGapXs) || variantConfig?.xs?.gridRowGap,
            gridColumnGap: toRem(gridColumnGapXs) || variantConfig?.xs?.gridColumnGa,
            minHeight: (minHeight?.xs && toRem(minHeight.xs)) || variantConfig?.xs?.minHeight || 'initial',
            padding: variantConfig?.xs?.padding || toRems([20, 20, 0, 25])
        },
        md: {
            gridTemplateColumns: columnsMd || variantConfig?.md?.gridTemplateColumns,
            gridTemplateAreas: areaMd || variantConfig?.md?.gridTemplateAreas,
            gridRowGap: toRem(gridRowGapMd) || variantConfig?.md?.gridRowGap,
            gridColumnGap: toRem(gridColumnGapMd) || variantConfig?.md?.gridColumnGap,
            minHeight: (minHeight?.md && toRem(minHeight.md)) || variantConfig?.md?.minHeight || 'initial',
            padding: variantConfig?.md?.padding || toRems([20, 20, 0, 25])
        },
        lg: {
            gridTemplateColumns: columnsLg || variantConfig?.lg?.gridTemplateColumns,
            gridTemplateAreas: areaLg || variantConfig?.lg?.gridTemplateAreas,
            gridRowGap: toRem(gridRowGapLg) || variantConfig?.lg?.gridRowGap,
            gridColumnGap: toRem(gridColumnGapLg) || variantConfig?.lg?.gridColumnGap,
            minHeight: (minHeight?.md && toRem(minHeight.md)) || variantConfig?.lg?.minHeight || 'initial',
            padding: variantConfig?.lg?.padding || toRems([50, 50, 0])
        }
    }
    const isGridXs = !!config.xs.gridTemplateColumns && !!config.xs.gridTemplateColumns

    return {
        position: 'relative',
        width: '100%',
        marginTop: toRem(22),
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(45)
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: toRem(40),
            paddingRight: toRem(10)
        },
        '& .cardsBlock_separator': {
            height: toRem(20),
            width: '100%'
        },
        '& .cardsBlock_title': {
            marginLeft: toRem(30),
            position: 'absolute',
            top: toRem(-20),
            background: theme.colors.background.body.default,
            fontFamily: theme.typography.fontFamilyAlt,
            fontStyle: theme.typography.font.style.italic,
            fontWeight: theme.typography.fontWeight.medium,
            fontSize: toRem(24),
            color: theme.colors.text.default,
            zIndex: theme.zIndex.over,
            [theme.breakpoints.up('md')]: {
                marginLeft: toRem(55),
                top: toRem(-40),
                fontSize: toRem(46)
            },
            transition: 'color 0.3s'
        },
        '& .cardsBlock_title:hover': {
            textDecoration: url ? 'underline' : 'none',
            color: url ? theme.colors.text.accent : theme.colors.text.default,
            cursor: url ? 'pointer' : 'auto'
        },
        '& .cardsBlock_inner': {
            padding: config?.lg.padding,
            position: 'relative',
            border: !noBorder && `1px solid ${theme.colors.border.default}`,
            backgroundColor: theme.colors.background.body.default,
            [theme.breakpoints.down('sm')]: {
                padding: config?.md.padding,
                borderLeft: 'none'
            }
        },
        '& .cardsBlock_list': {
            ...(isGridXs && {
                display: 'grid',
                gridTemplateColumns: config?.sm?.gridTemplateColumns,
                gridColumnGap: config?.sm?.gridColumnGap,
                gridRowGap: config?.sm?.gridRowGap,
                alignItems: config?.sm?.alignItems
            }),
            [theme.breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: config?.md.gridTemplateColumns,
                gridColumnGap: config?.md.gridColumnGap,
                gridRowGap: config?.md.gridRowGap,
                alignItems: config?.md.alignItems
            },
            [theme.breakpoints.up('lg')]: {
                gridTemplateColumns: config?.lg.gridTemplateColumns,
                gridColumnGap: config?.lg.gridColumnGap,
                gridRowGap: config?.lg.gridRowGap,
                alignItems: config?.lg.alignItems
            }
        },
        '& .cardsBlock_item': {
            ...(variant === 'alpha' && {
                [theme.breakpoints.up('md')]: {
                    '&:nth-of-type(odd)': {
                        marginTop: toRem(75)
                    }
                }
            }),
            ...(variant === 'beta' && {
                [theme.breakpoints.up('lg')]: {
                    '&:first-of-type': {
                        gridColumn: '1/3',
                        gridRow: '1/3'
                    }
                }
            }),
            '& > div': {
                // @NOTE: added because widget items add padding that is not needed here
                paddingRight: 0
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: toRem(40)
            }
        },
        '& .cardsBlock_icon': {
            position: 'absolute',
            right: 0,
            bottom: 0
        },
        '& .cardsBlock_graphic': {
            position: 'absolute',
            right: toRem(-10),
            bottom: toRem(-20),
            [theme.breakpoints.up('md')]: {
                right: toRem(-30),
                bottom: toRem(-35)
            }
        }
    }
}

export default style
